import './js/src/functions';
import './js/src/modules';
import './js/src/header';
import './js/src/modals';
import './js/src/forms';
import './js/src/cookies';

document.addEventListener('DOMContentLoaded', () =>
  global.modules.init()
);

// css
import './css/common.css';
import './css/contact_us.css';